<template>
  <div>
    <v-flex v-if="!isiten()" class="bgcolor2">
      <v-img
        style="height: 55vw"
        alt="Profhilo beauty Center Shami Derma Jordan Amman"
        :src="bgpic"
        class="hidden-md-and-down"
      ></v-img>
      <img
        style="position: relative; left: 0%; height: 400px"
        alt="Profhilo beauty Center Shami Derma Jordan Amman"
        :src="bgpic"
        class="hidden-md-and-up"
      />
      <div class="bgcolor">
        <v-card-title
          class="
            pb-4
            justify-left
            center1
            meticula-title
            cyan--text
            hidden-md-and-down
          "
          >PROFHILO</v-card-title
        >

        <v-card-title
          class="
            pb-0
            pt-5
            justify-left
            center1
            meticula-title
            cyan--text
            hidden-md-and-up
          "
          >البروفايلو</v-card-title
        >
        <div class="center1 pb-0 text-right meticula artext">
حّل تجاعيد الوجه وبعض الاجزاء من الجسم قد يعتبر شيءٍ مزعج ومقلق للعديد من الأشخاص! لحسن الحظ، مع تقدم التكنولوجي والمعرفة الأكبر حول كيفية التعامل مع البشرة بشكل عام، يمكننا الآن استعادة نضارة  بشرتنا بشكل علمي وطبيعي أكثر مما سبق وذالك من خلال حلول جديدة مثل حقن البروفايلو 
<br><br>

ما هو علاج او حقن البروفايلو؟
<br><br>
تقنية جديدة في عالم تجميل التي تستخدم لتأخير علامات التقدم في العمر وآثار الشيخوخة واصلاح الفد الجلد والتجاعيد البارزة من خلال حقن حمض الهيالورونيك والفيتامينات والمعادن والأحماض الامينية ومضادات الأكسدة في الطبقات العليا من الجلد، تتميز ابرة البروفايلو بقلة درجة الألم وقلة درجة التدخل الجراحي بشكل ملحوظ ، حيث يتم استخدام حقن البروفايلو على منطقة الوجه، الصدر، الرقبة واليدين والذراعين والركبتين وأي مكان لتخلص من التجاعيد 

        </div>
        <br />
        <br class="hidden-sm-and-down" />
        <br class="hidden-sm-and-down" />

        <div :class="getClass()">
          <v-card outlined class="transparent" align="center">
            <v-btn
              @click="overlay = !overlay"
              :height="getheight()"
              rounded
              :class="getbtnClass()"
              color="cyan"
              depressed
              ><v-card
                outlined
                class="transparent hidden-md-and-down artext"
                style="right: 1px; color: white"
                >احجز استشارتك المجانية الآن!</v-card
              >
              <span class="hidden-md-and-up artext">
                احجز استشارتك المجانية الآن!</span
              ></v-btn
            >
          </v-card>
          <br />
          <br />
          <br />

          <br class="hidden-sm-and-down" />
          <br class="hidden-sm-and-down" />
          <v-overlay :value="overlay" @click="overlay = false">
            <v-card
              color="#00abc8"
              class="hidden-md-and-down rounded-card"
              max-width="500"
              min-height="400"
            >
              <v-card shaped outlined class="rounded-card transparent pa-8">
                <v-icon
                  transparent
                  class="center5"
                  @click="overlay = false"
                  dark
                  >mdi-window-close</v-icon
                >
                <br />
                <Form valForm="HydraFacial"></Form>
              </v-card>
            </v-card>
            <v-card
              color="#00abc8"
              class="rounded-card hidden-md-and-up"
              max-width="500"
              height="346"
            >
              <v-card
                outlined
                class="rounded-card transparent pl-5 pr-5 pt-1 pb-0"
              >
                <v-card
                  style="top: 24px"
                  align="center"
                  outlined
                  class="transparent"
                >
                  <div class="hidden-md-and-up" style="al">
                    <span style="font-size: 20px; line-height: 0px"
                      >Fill Your Information & <br />
                      We Will Contact You
                    </span>
                  </div>
                </v-card>
                <v-icon
                  depressed
                  @click="overlay = false"
                  class="center6 hidden-md-and-down"
                  dark
                  >mdi-window-close</v-icon
                >

                <Form class="pl-8 pr-8" valForm="HomePage"></Form>
                <v-icon
                  depressed
                  @click="overlay = false"
                  class="center6 hidden-md-and-up"
                  style="top: -315px; left: 255px; font-size: 20px"
                  dark
                  >mdi-window-close
                </v-icon>
              </v-card>
            </v-card>
          </v-overlay>
        </div>
      </div>
    </v-flex>
    <v-flex v-if="isiten()" class="bgcolor2">
      <v-img
        style="height: 55vw"
        alt="Profhilo beauty Center Shami Derma Jordan Amman"
        :src="bgpic"
        class="hidden-md-and-down"
      ></v-img>
      <img
        style="
          position: relative;
          left: 50%;
          transform: translate(-55%, 0);
          height: 400px;
        "
        alt="Profhilo beauty Center Shami Derma Jordan Amman"
        :src="bgpic"
        class="hidden-md-and-up"
      />
      <div class="bgcolor">
        <v-card-title
          class="
            pb-4
            justify-left
            center1
            meticula-title
            cyan--text
            hidden-md-and-down
          "
          >PROFHILO</v-card-title
        >

        <v-card-title
          class="
            pb-0
            pt-5
            justify-left
            center1
            meticula-title
            cyan--text
            hidden-md-and-up
          "
          >PROFHILO</v-card-title
        >

        <div class="center1 pb-0 text-left meticula">
          Hyaluronic acid is one of the main components of the skin that helps
          give it radiance and a youthful look. As we age, the skin’s ability to
          produce it own hyaluronic acid decreases resulting in dry, dull and
          thin skin.
        </div>

        <div class="center1 pb-0 text-left meticula">
          Skin boosters such as profhilo ( there are other skin boosters such as
          Volite from Juvederm) work to improve the skin’s overall quality by
          boosting hydration levels deep inside the skin and increasing its
          smoothness, elasticity and firmness resulting in healthy skin with a
          beautiful glow. Skin booster treatments help to revitalize, nourish
          and hydrate the skin and are clinically proven for treatment of the
          face, neck, décolletage and hands. They are specially formulated to
          provide deep skin hydration and nourishment and are suitable for
          almost all skin types.
        </div>

        <div class="center1 pb-0 text-left meticula">
          Visible improvements to the skin can usually be seen after a single
          treatment although a course of two to three treatments are recommended
          for optimum results.
        </div>

        <div class="center1 text-left meticula">
          At Shami Derma, Profhilo can be the solution for many issues. you can
          book your complimentary session with our dermatologists right here in
          the heart of Amman
        </div>
        <br />
        <br class="hidden-sm-and-down" />
        <br class="hidden-sm-and-down" />

        <div :class="getClass()">
          <v-card outlined class="transparent" align="center">
            <v-btn
              @click="overlay = !overlay"
              :height="getheight()"
              rounded
              :class="getbtnClass()"
              color="cyan"
              depressed
              ><v-card
                outlined
                class="transparent hidden-md-and-down"
                style="right: 1px; color: white"
                >Book Your Complimentary Consultation Now!</v-card
              >
              <span class="hidden-md-and-up">
                Book Your Complimentary Consultation Now!</span
              ></v-btn
            >
          </v-card>
          <br />
          <br />
          <br />

          <br class="hidden-sm-and-down" />
          <br class="hidden-sm-and-down" />
          <v-overlay :value="overlay" @click="overlay = false">
            <v-card
              color="#00abc8"
              class="hidden-md-and-down rounded-card"
              max-width="500"
              min-height="400"
            >
              <v-card shaped outlined class="rounded-card transparent pa-8">
                <v-icon
                  transparent
                  class="center5"
                  @click="overlay = false"
                  dark
                  >mdi-window-close</v-icon
                >
                <br />
                <Form valForm="HydraFacial"></Form>
              </v-card>
            </v-card>
            <v-card
              color="#00abc8"
              class="rounded-card hidden-md-and-up"
              max-width="500"
              height="346"
            >
              <v-card
                outlined
                class="rounded-card transparent pl-5 pr-5 pt-1 pb-0"
              >
                <v-card
                  style="top: 24px"
                  align="center"
                  outlined
                  class="transparent"
                >
                  <div class="hidden-md-and-up" style="al">
                    <span style="font-size: 20px; line-height: 0px"
                      >Fill Your Information & <br />
                      We Will Contact You
                    </span>
                  </div>
                </v-card>
                <v-icon
                  depressed
                  @click="overlay = false"
                  class="center6 hidden-md-and-down"
                  dark
                  >mdi-window-close</v-icon
                >

                <Form class="pl-8 pr-8" valForm="HomePage"></Form>
                <v-icon
                  depressed
                  @click="overlay = false"
                  class="center6 hidden-md-and-up"
                  style="top: -315px; left: 255px; font-size: 20px"
                  dark
                  >mdi-window-close
                </v-icon>
              </v-card>
            </v-card>
          </v-overlay>
        </div>
      </div>
    </v-flex>
  </div>
</template>

<script>
// @ is an alias to /src
import Form from "../../components/Form";
import bgpic from "../../assets/pic/Profhilo 2.jpg";

export default {
  name: "Portfhilo",
  components: {
    Form,
  },

  data: () => ({
    overlay: false,
    windowWidth: window.innerWidth,
    windowHeigh: window.innerHeight / 2,
    bgpic,
  }),

  metaInfo: {
    title: "Shami Derma | ProFhilo Jordan",
    meta: [
      {
        name: "description",
        content:
          "Profhilo work to improve the skin’s quality by boosting hydration levels inside the skin and increasing its smoothness, elasticity and firmness at Shami Derma",
      },
    ],
  },

  methods: {
    getClass() {
      if (this.windowWidth < 1100) {
        return "center2";
      } else {
        return "center3";
      }
    },
    getbtnClass() {
      if (this.windowWidth < 1100) {
        return "rounded-card white--text text-transform-none meticula-btn2";
      } else {
        return "rounded-card pr-4 white--text text-transform-none meticula-btn";
      }
    },
    getheight() {
      if (this.windowWidth < 1100) {
        return "";
      } else {
        return "50";
      }
    },
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.meticula {
  font-family: meticula;
  font-size: calc(0.5vw + 0.13vmin + 14px);
  color: #808080;
  letter-spacing: -0.6px;
  word-spacing: 0px;
}

.meticula-title {
  font-family: meticula;
  font-size: calc(0.5vw + 0.55vmin + 22px);
}

.center1 {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.center5 {
  margin-left: 95.5%;
  margin-top: -5%;
}

.center2 {
  margin: auto;
  width: 73%;
}

.center3 {
  margin: auto;
  width: 45%;
  padding: 10px;
}

.center4 {
  margin-top: -50%;
  margin-left: 33.8%;
  width: 80%;
}

.rounded-card {
  border-radius: 50px;
}

.text-transform-none {
  text-transform: none !important;
}

#rcorners2 {
  border-radius: 25px;
  border: 2px solid cadetblue;
  background-color: cadetblue;
  padding: 20px;
  width: calc(1px + 50vh);
  height: calc(20px + 40vh);

  margin-left: 40%;
}

.bgcolor {
  margin: 5% 0 0vw 0;
  background-color: #eaf8fa;
}

.bgcolor2 {
  background-color: #eaf8fa;
  min-height: 100%;
  overflow: hidden;
}

.meticula-btn {
  font-family: meticula;
  font-size: calc(0.5vw + 0.13vmin + 14px);

  width: 92%;

  letter-spacing: 0.5px;
}

.meticula-btn2 {
  font-family: meticula;
  font-size: 2.8vw;
  letter-spacing: 0.5px;
  right: 1.5vw;
}

.artext {
  font-family: ar !important;
}
</style>
