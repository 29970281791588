<template>
  <v-card outlined class="transparent">
    <br />
    <br />
    <br class="hidden-md-and-up" />
    <v-card-title
      v-if="isiten()"
      class="justify-center meticula-title cyan--text"
      >Body Shaping Services</v-card-title
    >
    <v-card-title
      v-if="!isiten()"
      class="justify-center meticula-title cyan--text artext"
      >اجراءات نحت الجسم</v-card-title
    >
    <div class="center1">
      <v-col>
        <v-row>
          <v-spacer />
          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bc/coolsculpting">
                <v-img
                  alt="Cool Sculpting Center Shami Derma Jordan Amman"
                  @mouseover="CSO = CSB"
                  @mouseleave="CSO = CS"
                  :src="CSO"
                  :lazy-src="CSO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
                >Coolsculpting</v-card-title
              >
            </v-card>
          </v-flex>
          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bc/exima">
                <v-img
                  @mouseover="EXO = EXB"
                  @mouseleave="EXO = EX"
                  :src="EXO"
                  :lazy-src="EXO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
                >Eximia</v-card-title
              >
            </v-card>
          </v-flex>
          <v-spacer />

          <!-- <v-flex xs12 md12>
            <v-card outlined class="transparent">
              <v-card-title class="display-1 justify-center cyan--text">Title</v-card-title>
              <div
                class="headline"
              >I'm baby yr stumptown quinoa copper mug glossier. PBR&B palo santo yuccie put a bird on it fanny pack mustache. Vice skateboard pug cliche, four loko blog edison bulb farm-to-table keffiyeh tote bag lomo blue bottle art party messenger bag chartreuse. Freegan distillery kickstarter blue bottle banjo. Organic tousled microdosing hoodie banh mi shaman skateboard pug 3 wolf moon marfa</div>
            </v-card>
          </v-flex>-->
        </v-row>
      </v-col>
    </div>
    <br class="hidden-sm-and-down" />
    <br class="hidden-sm-and-down" />
  </v-card>
</template>

<script>
// @ is an alias to /src
import EX from "../assets/pic/Eximia.jpg";
import CS from "../assets/pic/Coolsculpting.jpg";

export default {
  name: "Bc",
  data: () => ({
    EX,
    CS,
    EXO: EX,
    CSO: CS,
    EXB: "https://i.imgur.com/T29zitp.png",
    CSB: "https://i.imgur.com/0DCckwO.png",
  }),

  metaInfo: {
    title: "Shami Derma | Body Shaping",
    meta: [
      {
        name: "description",
        content:
          "Shami Derma Offers Coolscultping and Exima As Part Of Their Body Shaping Treatments!",
      },
    ],
  },

  components: {},
  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getMa() {
      if (this.isMobile()) {
        return "ma-1 transparent";
      } else {
        return "ma-4 transparent";
      }
    },
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.center1 {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.meticula {
  font-family: meticula !important;
  font-size: calc(11.8px + 0.5vw);
  line-height: 100%;
}

.meticula-title {
  font-family: meticula !important;
  font-size: calc(25px + 1vw);
}

.artext {
  font-family: ar !important;
}
</style>
