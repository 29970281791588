<template>
  <v-card align="center" class="transparent" outlined>
    <br />
    <br />
    <br class="hidden-md-and-up" />
    <v-card-title
      v-if="isiten()"
      class="justify-center meticula-title cyan--text"
      >Beauty Treatments</v-card-title
    >
    <v-card-title
      v-if="!isiten()"
      class="justify-center meticula-title cyan--text artext"
      >اجراءات التجميلة</v-card-title
    >
    <div class="center1">
      <v-col>
        <v-row>
          <v-spacer />
          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bt/lhr">
                <v-img
                  alt="Laser Hair Removal beauty Center Shami Derma Jordan Amman"
                  @mouseover="HFO = HFB"
                  @mouseleave="HFO = HF"
                  :src="HFO"
                  :lazy-src="HFO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
              >
                Laser Hair
                <div>Removal</div>
              </v-card-title>
            </v-card>
          </v-flex>
          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bt/rc">
                <v-img
                  alt="Red Carpet beauty Center Shami Derma Jordan Amman"
                  @mouseover="MNO = MNB"
                  @mouseleave="MNO = MN"
                  :src="MNO"
                  :lazy-src="MNO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
                >Red Carpet</v-card-title
              >
              <!-- <v-card-title class="meticula justify-center cyan--text"
                >Microneedling</v-card-title
              >-->
            </v-card>
          </v-flex>
          <!-- <v-hover v-slot:default="{ hover }">
            <v-flex xs12 md4>
              <v-card outlined class="ma-12 transparent">
                <router-link to="/services/fts/diamondpeel">
                  <v-img :src="DP">
                    <v-expand-transition>
                      <v-card-title
                        v-if="hover"
                        class="meticula justify-center d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal white--text"
                        style="height: 100%;"
                        >Diamond Peel</v-card-title
                      >
                    </v-expand-transition>
                  </v-img>
                </router-link>
              </v-card>
            </v-flex>
          </v-hover>-->
          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bt/pf">
                <v-img
                  alt="ProFhilo beauty Center Shami Derma Jordan Amman"
                  @mouseover="DPO = DPB"
                  @mouseleave="DPO = DP"
                  :src="DPO"
                  :lazy-src="DPO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
                >ProFhilo</v-card-title
              >
            </v-card>
          </v-flex>
          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bt/mt">
                <v-img
                  alt="Mesotherapy beauty Center Shami Derma Jordan Amman"
                  @mouseover="CPO = CPB"
                  @mouseleave="CPO = CP"
                  :src="CPO"
                  :lazy-src="CPO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
                >Mesotherapy</v-card-title
              >
              <!-- <v-card-title class="meticula justify-center cyan--text"
                >Crystal Peel</v-card-title
              >-->
            </v-card>
          </v-flex>
          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bt/aw">
                <v-img
                  alt="Anti Wrinkle beauty Center Shami Derma Jordan Amman"
                  @mouseover="CLO = CLB"
                  @mouseleave="CLO = CL"
                  :src="CLO"
                  :lazy-src="CLO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
                >Anti Wrinkle</v-card-title
              >
              <!-- <v-card-title class="meticula justify-center cyan--text"
                >CO2 Laser</v-card-title
              >-->
            </v-card>
          </v-flex>

          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bt/df">
                <v-img
                  alt="Dermal Fillers beauty Center Shami Derma Jordan Amman"
                  @mouseover="UTO = UTB"
                  @mouseleave="UTO = UT"
                  :src="UTO"
                  :lazy-src="UTO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
                >Dermal Fillers</v-card-title
              >
              <!-- <v-card-title class="meticula justify-center cyan--text"
                >Ultherapy</v-card-title
              >-->
            </v-card>
          </v-flex>
          <v-flex xs6 md4>
            <v-card outlined :class="getMa()">
              <router-link to="/services/bt/prp">
                <v-img
                  alt="PRP beauty Center Shami Derma Jordan Amman"
                  @mouseover="CCO = CCB"
                  @mouseleave="CCO = CC"
                  :src="CCO"
                  :lazy-src="CCO"
                >
                  <template v-slot:placeholder>
                    <v-row
                      class="fill-height ma-0"
                      align="center"
                      justify="center"
                    >
                      <v-progress-circular
                        indeterminate
                        color="grey lighten-5"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </router-link>
              <v-card-title
                v-if="isMobile()"
                class="meticula justify-center cyan--text"
                >PRP</v-card-title
              >
              <!-- <v-card-title class="meticula justify-center cyan--text"
                >Caci</v-card-title
              >-->
            </v-card>
          </v-flex>
          <v-spacer />
        </v-row>
      </v-col>
    </div>
    <br class="hidden-sm-and-down" />
    <br class="hidden-sm-and-down" />
  </v-card>
</template>

<script>
// @ is an alias to /src
import HF from "../assets/pic/Laser Hair Removal.jpg";
import MN from "../assets/pic/Red Carpet 1.jpg";
import DP from "../assets/pic/Profhilo.jpg";
import CP from "../assets/pic/Mesotherapy 1.jpg";
import CL from "../assets/pic/Botox.jpg";
import UT from "../assets/pic/Filler 1.jpg";
import CC from "../assets/pic/PRP 1.jpg";

export default {
  name: "BT",
  data: () => ({
    HF,
    HFB: "https://i.imgur.com/Wn8a3AU.png",
    MN,
    MNB: "https://i.imgur.com/xorPD8K.png",
    DP,
    DPB: "https://i.imgur.com/IRRv88W.png",
    CP,
    CPB: "https://i.imgur.com/1re7cAG.png",
    CL,
    CLB: "https://i.imgur.com/eNvWxEb.png",
    UT,
    UTB: "https://i.imgur.com/Hc7cTiE.png",
    CC,
    CCB: "https://i.imgur.com/FBQM99l.png",
    src12: false,
    HFO: HF,
    key12: 0,
    MNO: MN,
    DPO: DP,
    CPO: CP,
    CLO: CL,
    UTO: UT,
    CCO: CC,
    hover: false,
    phone: true,
  }),

  metaInfo: {
    title: "Shami Derma | Beauty Treatments",
    meta: [
      {
        name: "description",
        content:
          "Shami Derma Offers A Variety Of Beauty Treatments Such As Laser Hair Remova, Red Carpet, Mesotherapy And Much More!",
      },
    ],
  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    getMa() {
      if (this.isMobile()) {
        return "ma-1 my-0 mt-0  transparent";
      } else {
        return "ma-4 transparent";
      }
    },
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
  },
  components: {},
};
</script>
<style scoped>
.meticula {
  font-family: meticula;
  font-size: calc(0.9rem);
  line-height: 100%;
}

.meticula-title {
  font-family: meticula;
  font-size: calc(24px + 1vw);
}

.center1 {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.blureffect {
  opacity: 100%;
}

.blureffect:hover {
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.hoverCard {
  opacity: 0%;
  height: 100%;
}

.hoverCard:hover {
  opacity: 100%;
}

.v-card--reveal {
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  filter: blur(0px);
  position: absolute;
  width: 100%;
}

.artext {
  font-family: ar !important;
}
</style>
