<template>
  <div>
    <v-flex v-if="isiten()" class="bgcolor2">
      <v-img
        style="height: 55vw"
        alt="Crystal Peel Jordan Amman Shami Derma"
        class="hidden-md-and-down"
        :src="bgpic"
      ></v-img>
      <img
        style="
          position: relative;
          left: 50%;
          transform: translate(-70%, 0);
          height: 400px;
        "
        alt="Crystal Peel Jordan Amman Shami Derma"
        class="hidden-md-and-up"
        :src="bgpic"
      />
      <div class="bgcolor">
        <v-card-title
          class="
            pb-4
            justify-left
            center1
            meticula-title
            cyan--text
            hidden-md-and-down
          "
          >CRYSTAL PEEL</v-card-title
        >

        <v-card-title
          class="
            pb-0
            pt-5
            justify-left
            center1
            meticula-title
            cyan--text
            hidden-md-and-up
          "
          >CRYSTAL PEEL</v-card-title
        >

        <div class="center1 pb-0 text-left meticula">
          It's time for a Crystal Peel. Remove the dead cells off your skin
          through this facial treatment for a glowing face.
        </div>

        <div class="center1 pb-0 text-left meticula">
          Crystal Peel is a deep exfoliating procedure that resurfaces the upper
          layers of the skin by using a fine jet of aluminum oxide crystals to
          shed off your skin without any pain while a special micro vacuum
          clears away the crystals along with the dead skin cells, this helps
          your skin cell reproduction and promotes collagen and elastic growth.
        </div>

        <div class="center1 pb-0 text-left meticula">
          Crystal Peel gives our patients the results they want and helps them
          get rid of acne scars, fine wrinkles, and improving skin texture and
          color by removing the upper layer of dead skin.
        </div>

        <div class="center1 text-left meticula">
          Shami Derma can help you understand this procedure in detail, Visit
          our clinic in Amman and start your treatment for better skin.
        </div>
        <br />
        <br class="hidden-sm-and-down" />
        <br class="hidden-sm-and-down" />

        <div :class="getClass()">
          <v-card outlined class="transparent" align="center">
            <v-btn
              @click="overlay = !overlay"
              :height="getheight()"
              rounded
              :class="getbtnClass()"
              color="cyan"
              depressed
              ><v-card
                outlined
                class="transparent hidden-md-and-down"
                style="right: 1px; color: white"
                >Book Your Complimentary Consultation Now!</v-card
              >
              <span class="hidden-md-and-up">
                Book Your Complimentary Consultation Now!</span
              ></v-btn
            >
          </v-card>
          <br />
          <br />
          <br />

          <br class="hidden-sm-and-down" />
          <br class="hidden-sm-and-down" />
          <v-overlay :value="overlay" @click="overlay = false">
            <v-card
              color="#00abc8"
              class="hidden-md-and-down rounded-card"
              max-width="500"
              min-height="400"
            >
              <v-card shaped outlined class="rounded-card transparent pa-8">
                <v-icon
                  transparent
                  class="center5"
                  @click="overlay = false"
                  dark
                  >mdi-window-close</v-icon
                >
                <br />
                <Form valForm="HydraFacial"></Form>
              </v-card>
            </v-card>
            <v-card
              color="#00abc8"
              class="rounded-card hidden-md-and-up"
              max-width="500"
              height="346"
            >
              <v-card
                outlined
                class="rounded-card transparent pl-5 pr-5 pt-1 pb-0"
              >
                <v-card
                  style="top: 24px"
                  align="center"
                  outlined
                  class="transparent"
                >
                  <div class="hidden-md-and-up" style="al">
                    <span style="font-size: 20px; line-height: 0px"
                      >Fill Your Information & <br />
                      We Will Contact You
                    </span>
                  </div>
                </v-card>
                <v-icon
                  depressed
                  @click="overlay = false"
                  class="center6 hidden-md-and-down"
                  dark
                  >mdi-window-close</v-icon
                >

                <Form class="pl-8 pr-8" valForm="HomePage"></Form>
                <v-icon
                  depressed
                  @click="overlay = false"
                  class="center6 hidden-md-and-up"
                  style="top: -315px; left: 255px; font-size: 20px"
                  dark
                  >mdi-window-close
                </v-icon>
              </v-card>
            </v-card>
          </v-overlay>
        </div>
      </div>
    </v-flex>
    <v-flex v-if="!isiten()" class="bgcolor2">
      <v-img
        style="height: 55vw"
        alt="Crystal Peel Jordan Amman Shami Derma"
        class="hidden-md-and-down"
        :src="bgpic"
      ></v-img>
      <img
        style="position: relative; left: 20%; height: 400px"
        alt="Crystal Peel Jordan Amman Shami Derma"
        class="hidden-md-and-up"
        :src="bgpic"
      />
      <div class="bgcolor">
        <v-card-title
          class="
            pb-4
            justify-left
            center1
            meticula-title
            cyan--text
            hidden-md-and-down
          "
          >CRYSTAL PEEL</v-card-title
        >

        <v-card-title
          class="
            pb-0
            pt-5
            justify-left
            center1
            meticula-title
            cyan--text
            hidden-md-and-up
          "
          >CRYSTAL PEEL</v-card-title
        >

        <div class="center1 pb-0 text-right meticula artext">
          حان وقت التقشير الكريستالي. إزالة الخلايا الميتة من جلدك من خلال علاج
          الوجه هذا للحصول على وجه متوهج.
        </div>

        <div class="center1 pb-0 text-right meticula artext">
          التقشير الكريستالي هو إجراء تقشير عميق يعمل على إعادة سطح الجزء العلوي
          طبقات الجلد باستخدام نفاثة دقيقة من بلورات أكسيد الألومنيوم يتخلص من
          بشرتك دون أي ألم أثناء استخدام مكنسة كهربائية خاصة يزيل البلورات مع
          خلايا الجلد الميتة ، وهذا يساعد تكاثر خلايا بشرتك ويعزز نمو الكولاجين
          والمرن.
        </div>

        <div class="center1 pb-0 text-right meticula artext">
          يمنح Crystal Peel مرضانا النتائج التي يريدونها ويساعدهم التخلص من
          ندبات حب الشباب والتجاعيد الدقيقة وتحسين ملمس البشرة و اللون عن طريق
          إزالة الطبقة العليا من الجلد الميت.
        </div>

        <div class="center1 text-right meticula artext">
          يمكن أن تساعدك شامي ديرما على فهم هذا الإجراء بالتفصيل ، قم بزيارة
          عيادتنا في عمان وابدأ علاجك لبشرة أفضل.
        </div>
        <br />
        <br class="hidden-sm-and-down" />
        <br class="hidden-sm-and-down" />

        <div :class="getClass()">
          <v-card outlined class="transparent" align="center">
            <v-btn
              @click="overlay = !overlay"
              :height="getheight()"
              rounded
              :class="getbtnClass()"
              color="cyan"
              depressed
              ><v-card
                outlined
                class="transparent hidden-md-and-down artext"
                style="right: 1px; color: white"
                >احجز استشارتك المجانية الآن!</v-card
              >
              <span class="hidden-md-and-up artext">
                احجز استشارتك المجانية الآن!</span
              ></v-btn
            >
          </v-card>
          <br />
          <br />
          <br />

          <br class="hidden-sm-and-down" />
          <br class="hidden-sm-and-down" />
          <v-overlay :value="overlay" @click="overlay = false">
            <v-card
              color="#00abc8"
              class="hidden-md-and-down rounded-card"
              max-width="500"
              min-height="400"
            >
              <v-card shaped outlined class="rounded-card transparent pa-8">
                <v-icon
                  transparent
                  class="center5"
                  @click="overlay = false"
                  dark
                  >mdi-window-close</v-icon
                >
                <br />
                <Form valForm="HydraFacial"></Form>
              </v-card>
            </v-card>
            <v-card
              color="#00abc8"
              class="rounded-card hidden-md-and-up"
              max-width="500"
              height="346"
            >
              <v-card
                outlined
                class="rounded-card transparent pl-5 pr-5 pt-1 pb-0"
              >
                <v-card
                  style="top: 24px"
                  align="center"
                  outlined
                  class="transparent"
                >
                  <div class="hidden-md-and-up" style="al">
                    <span style="font-size: 20px; line-height: 0px"
                      >Fill Your Information & <br />
                      We Will Contact You
                    </span>
                  </div>
                </v-card>
                <v-icon
                  depressed
                  @click="overlay = false"
                  class="center6 hidden-md-and-down"
                  dark
                  >mdi-window-close</v-icon
                >

                <Form class="pl-8 pr-8" valForm="HomePage"></Form>
                <v-icon
                  depressed
                  @click="overlay = false"
                  class="center6 hidden-md-and-up"
                  style="top: -315px; left: 255px; font-size: 20px"
                  dark
                  >mdi-window-close
                </v-icon>
              </v-card>
            </v-card>
          </v-overlay>
        </div>
      </div>
    </v-flex>
  </div>
</template>

<script>
// @ is an alias to /src
import Form from "../components/Form";
import bgpic from "../assets/pic/Crystal Peel 2.jpg";

export default {
  name: "CrystalPeel",
  components: {
    Form,
  },

  data: () => ({
    overlay: false,
    windowWidth: window.innerWidth,
    windowHeigh: window.innerHeight / 2,
    bgpic,
  }),

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Derma | Crystal Peel Jordan",
    meta: [
      {
        name: "description",
        content:
          "Crystal Peel is a deep exfoliating procedure at Shami Derma that resurfaces the upper layers of the skin for the results everybody in Amman would want!",
      },
    ],
    // all titles will be injected into this template
  },

  methods: {
    getClass() {
      if (this.windowWidth < 1100) {
        return "center2";
      } else {
        return "center3";
      }
    },
    getbtnClass() {
      if (this.windowWidth < 1100) {
        return "rounded-card white--text text-transform-none meticula-btn2";
      } else {
        return "rounded-card pr-4 white--text text-transform-none meticula-btn";
      }
    },
    getheight() {
      if (this.windowWidth < 1100) {
        return "";
      } else {
        return "50";
      }
    },
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.meticula {
  font-family: meticula;
  font-size: calc(0.5vw + 0.13vmin + 14px);
  color: #808080;
  letter-spacing: -0.6px;
  word-spacing: 0px;
}

.meticula-title {
  font-family: meticula;
  font-size: calc(0.5vw + 0.55vmin + 22px);
}

.center1 {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.center5 {
  margin-left: 95.5%;
  margin-top: -5%;
}

.center2 {
  margin: auto;
  width: 73%;
}

.center3 {
  margin: auto;
  width: 45%;
  padding: 10px;
}

.center4 {
  margin-top: -50%;
  margin-left: 33.8%;
  width: 80%;
}

.rounded-card {
  border-radius: 50px;
}

.text-transform-none {
  text-transform: none !important;
}

#rcorners2 {
  border-radius: 25px;
  border: 2px solid cadetblue;
  background-color: cadetblue;
  padding: 20px;
  width: calc(1px + 50vh);
  height: calc(20px + 40vh);

  margin-left: 40%;
}

.bgcolor {
  margin: 4.9% 0 0vw 0;
  background-color: #eaf8fa;
}
.bgcolor2 {
  background-color: #eaf8fa;
  min-height: 100%;
  overflow: hidden;
}

.meticula-btn {
  font-family: meticula;
  font-size: calc(0.5vw + 0.13vmin + 14px);

  width: 92%;

  letter-spacing: 0.5px;
}

.meticula-btn2 {
  font-family: meticula;
  font-size: 2.8vw;
  letter-spacing: 0.5px;
  right: 1.5vw;
}

.artext {
  font-family: ar !important;
}
</style>

