<template>
  <div>
    <div v-if="isiten()" class="bgc">
      <v-img class="hidden-md-and-up" :src="z44" style="height: 400px"> </v-img>
      <v-img class="hidden-md-and-down" :src="z44" style="height: 55vw">
      </v-img>
      <div class="pad">
        <v-row class="about">
          <v-col sm="12" md="12" cols="12">
            <h1 class="mb-3 cyan--text hidden-md-and-down">ABOUT US</h1>

            <h1 class="mb-1 pt-3 cyan--text hidden-md-and-up">ABOUT US</h1>
            <p>
              Established in 2018 and located in the heart of Amman, SHAMI DERMA
              is a comprehensive medical and laser esthetic center offering
              up-to-date medical technology in the field of dermatology and
              non-surgical medical aesthetics for the skin and body. We at SHAMI
              DERMA provide our patients with state-of-the-art anti-aging and
              aesthetic technology in a comfortable, convenient and private
              setting. Our team of highly skilled and specialized physicians and
              medical therapists strive to deliver quality healthcare in a
              professional and compassionate manner with excellence in patient
              care and satisfaction in mind.
            </p>
          </v-col>
          <v-col sm="12" md="12" cols="12">
            <h2>Our Services</h2>

            <p class="my-auto psubh">Our Professional Services Consist of:</p>
            <ul class="mt-2 cyan--text">
              <li>
                <span>
                  Medical Services Acne Treatment, Sun Damage, Skin
                  Pigmentation, Eczema, Psoriasis, Vitiligo, Skin Tags and
                  Warts.
                </span>
              </li>

              <li class="my-6">
                <span>
                  Facial Services Acne Scar Removal, Chemical, Diamond and
                  Crystal Peel, Microdermabrasion, Mesotherapy, Hydrafacial, CO2
                  Fractional Laser, Skin Aging, Fine lines and Wrinkles, Dermal
                  Fillers and Botox, Non-Surgical Facelift (Ultherapy), Mole
                  Removal, Dark Circles, Platelet-Rich Plasma (PRP),
                  Micro-Needling.
                </span>
              </li>

              <li>
                <span>
                  Body Services Cellulite Reduction, Body Shaping Using Radio
                  Frequency, Cryolipolysis (CoolSculpting), Laser Hair Removal,
                  Scar Treatment, Decolletage Rejuvenation, Hand Rejuvenation,
                  Hair Loss.
                </span>
              </li>
            </ul>
            <br class="hidden-sm-and-down" />
            <br />
          </v-col>

          <!-- <v-col class="hidden-sm-and-down" cols="1">
          <v-icon x-large style="top: 120px">mdi-chevron-left</v-icon>
        </v-col> -->

          <v-col class="pad2 hidden-sm-and-down" cols="12">
            <Carousel
              style="margin: 0 4% 0 4%"
              class="vcar"
              autoplay="true"
              navigationEnabled="true"
              navigationClickTargetSize="100"
              paginationActiveColor="#00bcd4"
              navigationPrevLabel="<"
              navigationNextLabel=">"
              align="center"
              perPage="3"
            >
              <Slide v-for="(slide, i) in slides2" :key="i">
                <v-img
                  style="height: 17vw; width: 17vw"
                  :src="slide.src"
                ></v-img>
              </Slide>
            </Carousel>
          </v-col>

          <v-col class="hidden-md-and-up" sm="12" md="12" cols="12">
            <Carousel
              paginationActiveColor="#000000"
              align="center"
              perPage="1"
            >
              <Slide v-for="(slide, i) in slides" :key="i">
                <v-img width="220" height="220" :src="slide.src"></v-img>
              </Slide>
            </Carousel>
          </v-col>
        </v-row>
      </div>
      <br />
      <br />
    </div>
    <div v-if="!isiten()" class="bgc">
      <v-img class="hidden-md-and-up" :src="z44" style="height: 400px"> </v-img>
      <v-img class="hidden-md-and-down" :src="z44" style="height: 55vw">
      </v-img>
      <div class="pad">
        <v-row class="about">
          <v-col sm="12" md="12" cols="12">
            <h1 class="mb-3 cyan--text hidden-md-and-down artext">
              معلومات عنا
            </h1>

            <h1 class="mb-1 pt-3 cyan--text hidden-md-and-up artext">
              معلومات عنا
            </h1>
            <p class="artext">
              تأسست عام 2018 وتقع في قلب عمان ، شامي ديرما هو مركز طبي شامل
              للتجميل بالليزر أحدث التقنيات الطبية في مجال الأمراض الجلدية و
              جماليات طبية غير جراحية للبشرة والجسم. نحن في الشامي تقدم DERMA
              لمرضانا أحدث أنواع مكافحة الشيخوخة و التكنولوجيا الجمالية بشكل
              مريح ومريح وخاص ضبط. لدينا فريق من الأطباء ذوي المهارات العالية
              والمتخصصين و يسعى المعالجون الطبيون جاهدين لتقديم رعاية صحية عالية
              الجودة في أ بأسلوب احترافي ورحيم مع التميز في المريض الرعاية
              والرضا في الاعتبار.
            </p>
          </v-col>
          <v-col sm="12" md="12" cols="12">
            <h2 class="artext">خدماتنا</h2>

            <p class="my-auto psubh artext">تتكون خدماتنا المهنية من:</p>
            <ul class="mt-2 cyan--text artext">
              <li class="artext">
                <span>
                  الخدمات الطبية علاج حب الشباب، أضرار أشعة الشمس، الجلد التصبغ
                  ، الأكزيما ، الصدفية ، البهاق ، الزوائد الجلدية و البثور.
                </span>
              </li>

              <li class="my-6 artext">
                <span>
                  خدمات الوجه إزالة ندبات حب الشباب والمواد الكيميائية والماس و
                  التقشير الكريستالي ، التقشير الدقيق ، الميزوثيرابي ، هيدرا
                  فيشل ، ثاني أكسيد الكربون ليزر فراكشنال ، شيخوخة الجلد ،
                  الخطوط الدقيقة والتجاعيد ، الجلد الفيلر والبوتوكس ، شد الوجه
                  غير الجراحي (ألثيرابي) ، الشامة الإزالة ، الهالات السوداء ،
                  البلازما الغنية بالصفائح الدموية (PRP) ، إبرة دقيقة.
                </span>
              </li>

              <li class="artext">
                <span>
                  خدمات الجسم للحد من السيلوليت ، تشكيل الجسم باستخدام الراديو
                  التردد ، تفتيت الدهون بالتبريد (CoolSculpting) ، إزالة الشعر
                  بالليزر ، علاج الندبات ، تجديد منطقة الصدر ، تجديد شباب اليدين
                  ، تساقط الشعر.
                </span>
              </li>
            </ul>
            <br class="hidden-sm-and-down" />
            <br />
          </v-col>

          <!-- <v-col class="hidden-sm-and-down" cols="1">
          <v-icon x-large style="top: 120px">mdi-chevron-left</v-icon>
        </v-col> -->

          <v-col class="pad2 hidden-sm-and-down" cols="12">
            <Carousel
              style="margin: 0 4% 0 4%"
              class="vcar"
              autoplay="true"
              navigationEnabled="true"
              navigationClickTargetSize="100"
              paginationActiveColor="#00bcd4"
              navigationPrevLabel="<"
              navigationNextLabel=">"
              align="center"
              perPage="3"
            >
              <Slide v-for="(slide, i) in slides2" :key="i">
                <v-img
                  style="height: 17vw; width: 17vw"
                  :src="slide.src"
                ></v-img>
              </Slide>
            </Carousel>
          </v-col>

          <v-col class="hidden-md-and-up" sm="12" md="12" cols="12">
            <Carousel
              paginationActiveColor="#000000"
              align="center"
              perPage="1"
            >
              <Slide v-for="(slide, i) in slides" :key="i">
                <v-img width="220" height="220" :src="slide.src"></v-img>
              </Slide>
            </Carousel>
          </v-col>
        </v-row>
      </div>
      <br />
      <br />
    </div>
  </div>
</template>
<script>
import c1 from "../assets/aboutuspic/c1.jpg";
import c2 from "../assets/aboutuspic/c2.jpg";
import c4 from "../assets/aboutuspic/c4.jpg";
import z11 from "../assets/aboutuspic/z11.jpg";
import z22 from "../assets/aboutuspic/z22.jpg";
import z33 from "../assets/aboutuspic/z33.jpg";
import z44 from "../assets/aboutuspic/z44.jpg";
import { Carousel, Slide } from "vue-carousel";

export default {
  components: {
    Carousel,
    Slide,
  },
  data: () => ({
    c1,
    c2,
    c4,
    z11,
    z44,
    slides: [
      {
        src: c1,
      },
      {
        src: c2,
      },
      {
        src: z11,
      },
      {
        src: c4,
      },
    ],
    slides2: [
      {
        src: c2,
      },
      {
        src: z11,
      },
      {
        src: z33,
      },
      {
        src: z44,
      },
      {
        src: z22,
      },
      {
        src: c2,
      },
      {
        src: c2,
      },
      {
        src: c4,
      },
    ],
  }),
  metaInfo: {
    title: "Shami Derma | About Us",
    meta: [
      {
        name: "description",
        content:
          "Shami Derma Amman Jordan, Our Story, Get Your Complimentary Consultation Today!",
      },
    ],
  },
  methods: {
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.meticula {
  font-family: meticula;
  font-weight: lighter;
}

::v-deep .VueCarousel-navigation-button {
  font-size: 30px;
  margin: 0 5% 0 5%;
  top: 40%;
  color: #00bcd4;
}

p {
  font-size: calc(0.5vw + 0.13vmin + 14px);
}

::v-deep .VueCarousel-dot {
  height: 10px !important;
  width: 10px !important;
  background: radial-gradient(
    circle,
    rgba(100, 0, 0, 0) 0%,
    rgba(100, 0, 0, 0) 48%,
    #00bcd4 100%
  ) !important ;
  padding: 0px !important;
  margin-right: 2px !important;
  margin-left: 2px !important;
}

::v-deep .VueCarousel-dot--active {
  background-color: #00bcd4 !important;
}

::v-deep .VueCarousel-navigation-button:focus {
  outline: none;
}

.bgc {
  background-color: #eaf8fa;
}

.chev {
  z-index: 1;
}

.about {
  margin: 5.1% 0 0vw 0;
  font-size: calc(0.5vw + 0.13vmin + 14px);
  font-family: meticula;
  font-weight: lighter !important;
  color: #696969;
  line-height: 1.62;
}

.pad {
  padding: 0% 10% 0 10%;
}

.pad2 {
  padding: 0% 10vw 0 10vw;
}

h1 {
  font-family: meticula;
  color: #696969;
  font-weight: lighter;
  font-size: calc(0.5vw + 0.55vmin + 22px);
}

h2 {
  font-family: meticula;
  color: #808080;

  font-weight: lighter;
}

h3 {
  font-family: meticula;

  font-weight: lighter;
}

.artext {
  font-family: ar !important;
}

li {
  font-family: meticula;
  font-weight: lighter;
  font-size: 150%;
  line-height: 90%;
}

li span {
  font-weight: lighter;
  font-size: calc(0.5vw + 0.13vmin + 14px);
}

.btnT {
  text-transform: none !important;
  font-family: meticula;
  color: white;
  font-weight: lighter;
}

ul {
  list-style-type: square;
  padding-left: calc(1em + 1vw);
  font-weight: lighter;
}

.psubh {
  font-size: calc(0.5vw + 0.55vmin + 10px);
}
</style>
