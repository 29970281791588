<template>
  <div>
    <v-card v-if="isiten()" align="center" class="transparent" outlined>
      <br />
      <br />
      <br class="hidden-md-and-up" />
      <v-card-title class="justify-center meticula-title cyan--text"
        >Facial Treatments</v-card-title
      >
      <div class="center1">
        <v-col>
          <v-row>
            <v-spacer />
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/hydrafacial">
                  <v-img
                    alt="Hydrafacial Shami Derma Jordan Amman"
                    @mouseover="HFO = HFB"
                    @mouseleave="HFO = HF"
                    :src="HFO"
                    :lazy-src="HFO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >HydraFacial</v-card-title
                >
              </v-card>
            </v-flex>
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/ml">
                  <v-img
                    alt="Microneedling Shami Derma Jordan Amman"
                    @mouseover="MNO = MNB"
                    @mouseleave="MNO = MN"
                    :src="MNO"
                    :lazy-src="MNO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Microneedling</v-card-title
                >
              </v-card>
            </v-flex>
            <!-- <v-hover v-slot:default="{ hover }">
            <v-flex xs12 md4>
              <v-card outlined class="ma-12 transparent">
                <router-link to="/services/fts/diamondpeel">
                  <v-img :src="DP">
                    <v-expand-transition>
                      <v-card-title
                        v-if="hover"
                        class="meticula justify-center d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal white--text"
                        style="height: 100%;"
                        >Diamond Peel</v-card-title
                      >
                    </v-expand-transition>
                  </v-img>
                </router-link>
              </v-card>
            </v-flex>
          </v-hover>-->
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/diamondpeel">
                  <v-img
                    alt="Diamond Peel Shami Derma Jordan Amman"
                    @mouseover="DPO = DPB"
                    @mouseleave="DPO = DP"
                    :src="DPO"
                    :lazy-src="DPO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Diamond Peel</v-card-title
                >
              </v-card>
            </v-flex>
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/crystalpeel">
                  <v-img
                    alt="Crystal Peel Shami Derma Jordan Amman"
                    @mouseover="CPO = CPB"
                    @mouseleave="CPO = CP"
                    :src="CPO"
                    :lazy-src="CPO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Crystal Peel</v-card-title
                >
              </v-card>
            </v-flex>
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/co2laser">
                  <v-img
                    alt="CO2 C02 Laser Shami Derma Jordan Amman"
                    @mouseover="CLO = CLB"
                    @mouseleave="CLO = CL"
                    :src="CLO"
                    :lazy-src="CLO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >CO2 Laser</v-card-title
                >
              </v-card>
            </v-flex>

            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/ultherapy">
                  <v-img
                    alt="Ultherapy Shami Derma Jordan Amman"
                    @mouseover="UTO = UTB"
                    @mouseleave="UTO = UT"
                    :src="UTO"
                    :lazy-src="UTO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Ultherapy</v-card-title
                >
              </v-card>
            </v-flex>
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/caci">
                  <v-img
                    alt="Caci Shami Derma Jordan Amman"
                    @mouseover="CCO = CCB"
                    @mouseleave="CCO = CC"
                    :src="CCO"
                    :lazy-src="CCO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Caci</v-card-title
                >
              </v-card>
            </v-flex>
            <v-spacer />
          </v-row>
        </v-col>
      </div>
      <br class="hidden-sm-and-down" />
      <br class="hidden-sm-and-down" />
    </v-card>
    <v-card v-if="!isiten()" align="center" class="transparent" outlined>
      <br />
      <br />
      <br class="hidden-md-and-up" />
      <v-card-title class="justify-center meticula-title cyan--text artext"
        >علاجات الوجه</v-card-title
      >
      <div class="center1">
        <v-col>
          <v-row>
            <v-spacer />
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/hydrafacial">
                  <v-img
                    alt="Hydrafacial Shami Derma Jordan Amman"
                    @mouseover="HFO = HFB"
                    @mouseleave="HFO = HF"
                    :src="HFO"
                    :lazy-src="HFO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >HydraFacial</v-card-title
                >
              </v-card>
            </v-flex>
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/ml">
                  <v-img
                    alt="Microneedling Shami Derma Jordan Amman"
                    @mouseover="MNO = MNB"
                    @mouseleave="MNO = MN"
                    :src="MNO"
                    :lazy-src="MNO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Microneedling</v-card-title
                >
              </v-card>
            </v-flex>
            <!-- <v-hover v-slot:default="{ hover }">
            <v-flex xs12 md4>
              <v-card outlined class="ma-12 transparent">
                <router-link to="/services/fts/diamondpeel">
                  <v-img :src="DP">
                    <v-expand-transition>
                      <v-card-title
                        v-if="hover"
                        class="meticula justify-center d-flex transition-fast-in-fast-out grey darken-2 v-card--reveal white--text"
                        style="height: 100%;"
                        >Diamond Peel</v-card-title
                      >
                    </v-expand-transition>
                  </v-img>
                </router-link>
              </v-card>
            </v-flex>
          </v-hover>-->
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/diamondpeel">
                  <v-img
                    alt="Diamond Peel Shami Derma Jordan Amman"
                    @mouseover="DPO = DPB"
                    @mouseleave="DPO = DP"
                    :src="DPO"
                    :lazy-src="DPO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Diamond Peel</v-card-title
                >
              </v-card>
            </v-flex>
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/crystalpeel">
                  <v-img
                    alt="Crystal Peel Shami Derma Jordan Amman"
                    @mouseover="CPO = CPB"
                    @mouseleave="CPO = CP"
                    :src="CPO"
                    :lazy-src="CPO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Crystal Peel</v-card-title
                >
              </v-card>
            </v-flex>
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/co2laser">
                  <v-img
                    alt="CO2 C02 Laser Shami Derma Jordan Amman"
                    @mouseover="CLO = CLB"
                    @mouseleave="CLO = CL"
                    :src="CLO"
                    :lazy-src="CLO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >CO2 Laser</v-card-title
                >
              </v-card>
            </v-flex>

            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/ultherapy">
                  <v-img
                    alt="Ultherapy Shami Derma Jordan Amman"
                    @mouseover="UTO = UTB"
                    @mouseleave="UTO = UT"
                    :src="UTO"
                    :lazy-src="UTO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Ultherapy</v-card-title
                >
              </v-card>
            </v-flex>
            <v-flex xs6 md4>
              <v-card outlined :class="getMa()">
                <router-link to="/services/fts/caci">
                  <v-img
                    alt="Caci Shami Derma Jordan Amman"
                    @mouseover="CCO = CCB"
                    @mouseleave="CCO = CC"
                    :src="CCO"
                    :lazy-src="CCO"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </router-link>
                <v-card-title
                  v-if="isMobile()"
                  class="meticula justify-center cyan--text"
                  >Caci</v-card-title
                >
              </v-card>
            </v-flex>
            <v-spacer />
          </v-row>
        </v-col>
      </div>
      <br class="hidden-sm-and-down" />
      <br class="hidden-sm-and-down" />
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import HF from "../assets/pic/Hydrafacial 1.jpg";
import MN from "../assets/pic/Microneedling.jpg";
import DP from "../assets/pic/Diamond Peel.jpg";
import CP from "../assets/pic/Crystal Peel.jpg";
import CL from "../assets/pic/CO2 Laser.jpg";
import UT from "../assets/pic/Ultherapy.jpg";
import CC from "../assets/pic/Caci 1.jpg";

export default {
  name: "FTs",
  data: () => ({
    HF,
    HFB: "https://i.imgur.com/RmEdBBN.png",
    MN,
    MNB: "https://i.imgur.com/CdOwE58.png",
    DP,
    DPB: "https://i.imgur.com/zqYyh1V.png",
    CP,
    CPB: "https://i.imgur.com/IqzY7c4.png",
    CL,
    CLB: "https://i.imgur.com/Q1YTZUb.png",
    UT,
    UTB: "https://i.imgur.com/BrfQLJq.png",
    CC,
    CCB: "https://i.imgur.com/X54bYin.png",
    src12: false,
    HFO: HF,
    key12: 0,
    MNO: MN,
    DPO: DP,
    CPO: CP,
    CLO: CL,
    UTO: UT,
    CCO: CC,
    hover: false,
  }),

  metaInfo: {
    // if no subcomponents specify a metaInfo.title, this title will be used
    title: "Shami Derma | Facial Treatments",
    meta: [
      {
        name: "description",
        content:
          "Shami Derma Offers A Wide Range of Facial Treatments Such as Hydrafacial, Microneedling, Diamond Peel And Much More!",
      },
    ],
    // all titles will be injected into this template
  },

  methods: {
    isMobile() {
      if (
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        )
      ) {
        return true;
      } else {
        return false;
      }
    },
    isiten() {
      if (this.originalText == "EN") {
        return true;
      } else {
        return false;
      }
    },
    getMa() {
      if (this.isMobile()) {
        return "ma-1 my-0 mt-0 transparent";
      } else {
        return "ma-4 transparent";
      }
    },
  },
  components: {},
};
</script>
<style scoped>
.meticula {
  font-family: meticula;
  font-size: calc(0.8em);
  line-height: 100%;
}

.meticula-title {
  font-family: meticula;
  font-size: calc(24px + 1vw);
}

.center1 {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.blureffect {
  opacity: 100%;
}

.blureffect:hover {
  filter: blur(1px);
  -webkit-filter: blur(1px);
}

.hoverCard {
  opacity: 0%;
  height: 100%;
}

.hoverCard:hover {
  opacity: 100%;
}

.v-card--reveal {
  align-items: center;
  justify-content: center;
  opacity: 0.7;
  filter: blur(0px);
  position: absolute;
  width: 100%;
}

.artext {
  font-family: ar !important;
}
</style>
