<template>
  <div>
    <v-flex class="hidden-sm-and-down" xs12 md12>
      <v-card outlined class="transparent">
        <br />
        <br />
        <v-card-title
          v-if="isiten()"
          class="justify-center meticula-title cyan--text"
          >Our Services</v-card-title
        >
        <v-card-title
          v-if="!isiten()"
          class="justify-center meticula-title cyan--text artext"
          >خدماتنا</v-card-title
        >
        <br class="hidden-sm-and-down" />
        <div class="center1">
          <v-card outlined class="transparent">
            <router-link to="/services/fts">
              <v-img
                src="https://i.imgur.com/RBRVi0p.png"
                lazy-src="https://i.imgur.com/RBRVi0p.png"
                alt="Facial Treatments Shami Derma Jordan Amman Beauty Face"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </router-link>
            <br />
            <router-link to="/services/bc">
              <v-img
                src="https://i.imgur.com/JVR6JBA.png"
                lazy-src="https://i.imgur.com/JVR6JBA.png"
                alt="Body Shaping Treatments Shami Derma Jordan Amman Body Fat Lose"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </router-link>
            <br />
            <router-link to="/services/bt">
              <v-img
                src="https://i.imgur.com/IgpfGWH.png"
                lazy-src="https://i.imgur.com/IgpfGWH.png"
                alt="Beauty Treatments Shami Derma Jordan Amman Beauty Face"
              >
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </router-link>
            <br />
          </v-card>
          <br />
        </div>
      </v-card>
    </v-flex>

    <v-card outlined class="hidden-md-and-up transparent">
      <br />
      <br />
      <br class="hidden-md-and-up" />

      <v-card-title class="justify-center meticula-title pt-0 cyan--text"
        >Our Services</v-card-title
      >
      <div class="center1">
        <v-card outlined class="transparent">
          <router-link class="nodec" to="/services/fts">
            <v-img
              :src="ftspicm"
              :lazy-src="ftspicm"
              height="190"
              alt="Facial Treatments Shami Derma Jordan Amman Beauty Face"
            >
              <v-card
                style="left: 5%"
                outlined
                class="ml-8 mt-2 pt-12 pl-12 transparent"
              >
                <h1 align="center" class="mx-3 pt-6">Facial Treatments</h1>
              </v-card>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </router-link>
          <br />
          <router-link class="nodec" to="/services/bc">
            <v-img
              :src="bcpicm"
              :lazy-src="bcpicm"
              height="190"
              alt="Body Shaping Treatments Shami Derma Jordan Amman Body Fat Lose"
            >
              <v-card
                style="left: 0%"
                outlined
                class="mr-8 mt-2 pt-12 pr-12 transparent"
              >
                <h1 align="center" class="pt-6">Body Shaping Treatments</h1>
              </v-card>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </router-link>
          <br />
          <router-link class="nodec" to="/services/bt">
            <v-img
              :src="lhrpicm"
              :lazy-src="lhrpicm"
              height="190"
              alt="Beauty Treatments Shami Derma Jordan Amman Beauty Face"
            >
              <v-card
                style="left: 5%"
                outlined
                class="mx-3 ml-8 mt-2 pt-12 pl-12 transparent"
              >
                <h1 align="center" class="pt-6">Beauty Treatments</h1>
              </v-card>
              <template v-slot:placeholder>
                <v-row class="fill-height ma-0" align="center" justify="center">
                  <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
            </v-img>
          </router-link>
          <br />
        </v-card>
        <br />
      </div>
    </v-card>
  </div>
</template>


<script>
// @ is an alias to /src
import ftspicm from "../assets/pic/Facial Treatments 4.jpg";
import bcpicm from "../assets/pic/Body Shaping Treatment 4.jpg";
import lhrpicm from "../assets/pic/Beauty Treatments 4.jpg";

export default {
  name: "Services",
  components: {},
  data: () => ({
    ftspicm,
    bcpicm,
    lhrpicm,
  }),
  metaInfo() {
    return {
      title: "Shami Derma | Services",
      meta: [
        {
          name: "description",
          content:
            "Shami Derma Jordan Offers Many Services Like Facial Treatments, Body Scultping and Beauty Treatments! Get Your Complementary Consultation today",
        },
      ],
    };
  },
  methods: {
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
@font-face {
  font-family: "meticula";
  src: url(../assets/Meticula-Regular.ttf);
  font-style: normal;
  font-weight: 100;
}
.meticula {
  font-family: meticula;
  font-size: calc(12px + 1vw);
}

.meticula-title {
  font-family: meticula;
  font-size: calc(30px + 1vw);
}

h1 {
  font-family: meticula;
  font-size: 1.5rem;
  line-height: 110%;
  color: #464646;
  font-weight: lighter;
}

.artext {
  font-family: ar;
}

.nodec {
  text-decoration: none !important;
}

.center1 {
  margin: auto;
  width: 80%;
  padding: 10px;
}
</style>
