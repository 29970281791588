<template>
  <div>
    <v-card
      v-if="isiten()"
      outlined
      class="transparent"
      style="overflow: hidden"
    >
      <br />
      <br class="hidden-sm-and-down" />
      <br class="hidden-md-and-up" />
      <br class="hidden-md-and-up" />
      <v-card-title class="justify-center meticula-title cyan--text pt-0 pb-6"
        >Contact Us</v-card-title
      >
      <br class="hidden-sm-and-down" />
      <br class="hidden-sm-and-down" />

      <div>
        <v-row>
          <v-spacer />
          <v-flex xs12 md6>
            <v-card
              outlined
              align="center"
              class="center4 transparent hidden-md-and-down"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13540.990683382475!2d35.8568943!3d31.954177!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdac6bf7c9abe86ca!2sShami%20Derma!5e0!3m2!1sen!2sjo!4v1588377327442!5m2!1sen!2sjo"
                :width="getWidthMap()"
                height="460"
                class="pr-11"
                frameborder="0"
                style="border: 0"
                allowfullscreen
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </v-card>
            <v-card
              outlined
              align="center"
              class="center3 transparent hidden-md-and-up"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13540.990683382475!2d35.8568943!3d31.954177!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdac6bf7c9abe86ca!2sShami%20Derma!5e0!3m2!1sen!2sjo!4v1588377327442!5m2!1sen!2sjo"
                :width="getWidthMap()"
                height="460"
                class=""
                frameborder="0"
                style="border: 0"
                allowfullscreen
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </v-card>
          </v-flex>
          <v-flex xs12 md3 class="hidden-md-and-down">
            <v-card align="center" height="500" outlined class="transparent">
              <v-card-title
                class="cyan--text justify-left meticula hidden-sm-and-down"
                >Get In Touch</v-card-title
              >
              <v-card-title
                class="
                  cyan--text
                  justify-center
                  meticula-m
                  pt-5
                  hidden-md-and-up
                "
                >Get In Touch</v-card-title
              >
              <div>
                <v-card-title
                  class="meticula-small pt-0 pb-0 justify-left font-weight-bold"
                  >Address</v-card-title
                >
                <div class="text-left pl-4 meticula-tiny">
                  7th Circle, Amman, Jordan
                </div>
                <v-card-title
                  class="pb-0 meticula-small justify-left font-weight-bold"
                  >Opening Hours</v-card-title
                >
                <div class="text-left pl-4 meticula-tiny">
                  Saturday - Wednesday
                </div>
                <div class="text-left pl-4 meticula-tiny">
                  9:00 AM - 5:00 PM
                </div>
                <div class="text-left pl-4 meticula-tiny pt-2">Thusday</div>
                <div class="text-left pl-4 meticula-tiny">
                  9:00 AM - 2:00 PM
                </div>
                <v-card-title
                  class="pb-0 meticula-small justify-left font-weight-bold"
                  >Phone</v-card-title
                >
                <div class="text-left pl-4 meticula-tiny">+962 6 5822626</div>
                <div class="text-left pl-4 meticula-tiny">+962 79 9451777</div>
                <div class="text-left pl-4 meticula-tiny">+962 79 7676762</div>

                <v-card-title
                  class="pb-0 meticula-small justify-left font-weight-bold"
                  >E-Mail</v-card-title
                >
                <div class="text-left pl-4 meticula-tiny">
                  Info@shamiderma.com
                </div>
              </div>
            </v-card>
            <br class="hidden-sm-and-down" />
            <br class="hidden-sm-and-down" />
          </v-flex>

          <v-flex xs12 md3 class="hidden-md-and-up">
            <v-card align="center" height="500" outlined class="transparent">
              <v-card-title
                class="cyan--text justify-center meticula hidden-sm-and-down"
                >Get In Touch</v-card-title
              >
              <v-card-title
                class="
                  cyan--text
                  justify-center
                  meticula-m
                  pt-5
                  hidden-md-and-up
                "
                >Get In Touch</v-card-title
              >
              <div>
                <v-card-title
                  class="
                    meticula-small
                    pt-0
                    pb-0
                    justify-center
                    font-weight-bold
                  "
                  >Address</v-card-title
                >
                <div class="text-center meticula-tiny">
                  7th Circle, Amman, Jordan
                </div>
                <v-card-title
                  class="pb-0 meticula-small justify-center font-weight-bold"
                  >Opening Hours</v-card-title
                >
                <div class="text-center meticula-tiny">
                  Saturday - Wednesday
                </div>
                <div class="text-center meticula-tiny">9:00 AM - 5:00 PM</div>
                <div class="text-center meticula-tiny pt-2">Thusday</div>
                <div class="text-center meticula-tiny">9:00 AM - 2:00 PM</div>
                <v-card-title
                  class="pb-0 meticula-small justify-center font-weight-bold"
                  >Phone</v-card-title
                >
                <div class="text-center meticula-tiny">+962 6 5822626</div>
                <div class="text-center meticula-tiny">+962 79 9451777</div>
                <div class="text-center meticula-tiny">+962 79 7676762</div>

                <v-card-title
                  class="pb-0 meticula-small justify-center font-weight-bold"
                  >E-Mail</v-card-title
                >
                <div class="text-center meticula-tiny">Info@shamiderma.com</div>
              </div>
            </v-card>
            <br class="hidden-sm-and-down" />
            <br class="hidden-sm-and-down" />
          </v-flex>

          <v-spacer />

          <v-flex
            style="background-color: #eaf8fa"
            v-if="!submitLocal"
            xs12
            md12
          >
            <br class="hidden-sm-and-down" />
            <br class="hidden-sm-and-down" />
            <div style="padding: 0 10% 0 10%" :class="getFormWidth()">
              <v-card outlined class="transparent">
                <br />
                <h1
                  class="
                    pt-2
                    pb-3
                    cyan--text
                    justify-center
                    text-center
                    meticula-reg
                    pl-1
                    hidden-md-and-up
                    lineh
                  "
                >
                  Fill In Your Information & We Will Contact You
                </h1>

                <h1
                  class="
                    pt-2
                    cyan--text
                    justify-center
                    text-center
                    meticula-reg
                    pl-1
                    hidden-md-and-down
                    lineh1
                  "
                >
                  Fill In Your Information
                  <br />& We Will Contact You
                </h1>
              </v-card>
              <div class="center2 hidden-md-and-down">
                <Form color1="white"></Form>
              </div>
              <div class="hidden-md-and-up">
                <v-card outlined class="transparent" align="center">
                  <Form color1="white"></Form>
                </v-card>
              </div>
              <br />
              <br />
            </div>
          </v-flex>
        </v-row>
      </div>
    </v-card>
    <v-card
      v-if="!isiten()"
      outlined
      class="transparent"
      style="overflow: hidden"
    >
      <br />
      <br class="hidden-sm-and-down" />
      <br class="hidden-md-and-up" />
      <br class="hidden-md-and-up" />
      <v-card-title
        class="justify-center meticula-title cyan--text pt-0 pb-6 artext"
        >اتصل بنا</v-card-title
      >
      <br class="hidden-sm-and-down" />
      <br class="hidden-sm-and-down" />

      <div>
        <v-row>
          <v-spacer />
          <v-flex xs12 md6>
            <v-card
              outlined
              align="center"
              class="center4 transparent hidden-md-and-down"
              style="margin-right: 100px !important"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13540.990683382475!2d35.8568943!3d31.954177!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdac6bf7c9abe86ca!2sShami%20Derma!5e0!3m2!1sen!2sjo!4v1588377327442!5m2!1sen!2sjo"
                :width="getWidthMap()"
                height="460"
                class="pr-11"
                frameborder="0"
                style="border: 0"
                allowfullscreen
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </v-card>
            <v-card
              outlined
              align="center"
              class="center3 transparent hidden-md-and-up"
            >
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d13540.990683382475!2d35.8568943!3d31.954177!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xdac6bf7c9abe86ca!2sShami%20Derma!5e0!3m2!1sen!2sjo!4v1588377327442!5m2!1sen!2sjo"
                :width="getWidthMap()"
                height="460"
                class=""
                frameborder="0"
                style="border: 0"
                allowfullscreen
                aria-hidden="false"
                tabindex="0"
              ></iframe>
            </v-card>
          </v-flex>
          <v-flex xs12 md3 class="hidden-md-and-down">
            <v-card
              align="right"
              style="margin-left: 210px"
              height="500"
              outlined
              class="transparent"
            >
              <v-card-title
                class="
                  cyan--text
                  justify-right
                  meticula
                  hidden-sm-and-down
                  artext
                "
                style="direction: ltr"
                >ابقى على تواصل</v-card-title
              >
              <v-card-title
                class="
                  cyan--text
                  justify-center
                  meticula-m
                  pt-5
                  hidden-md-and-up
                  artext
                "
                >ابقى على تواصل</v-card-title
              >
              <div>
                <v-card-title
                  class="
                    meticula-small
                    pt-0
                    pb-0
                    justify-right
                    font-weight-bold
                    artext
                  "
                  style="direction: ltr"
                  >العنوان</v-card-title
                >
                <div class="text-left pl-4 meticula-tiny artext">
                  الدوار السابع ، عمان ، الأردن
                </div>
                <v-card-title
                  style="direction: ltr"
                  class="pb-0 meticula-small justify-right font-weight-bold"
                  >ساعات العمل</v-card-title
                >
                <div class="text-left pl-4 meticula-tiny artext">
                  السبت - الأربعاء
                </div>
                <div class="text-left pl-4 meticula-tiny">
                  9:00 AM - 5:00 PM
                </div>
                <div class="text-left pl-4 meticula-tiny pt-2 artext">
                  الخميس
                </div>
                <div class="text-left pl-4 meticula-tiny">
                  9:00 AM - 2:00 PM
                </div>
                <v-card-title
                  class="
                    pb-0
                    meticula-small
                    justify-right
                    font-weight-bold
                    artext
                  "
                  style="direction: ltr"
                  >هاتف</v-card-title
                >
                <div class="text-left pl-4 meticula-tiny">+962 6 5822626</div>
                <div class="text-left pl-4 meticula-tiny">+962 79 9451777</div>
                <div class="text-left pl-4 meticula-tiny">+962 79 7676762</div>

                <v-card-title
                  class="
                    pb-0
                    meticula-small
                    justify-right
                    font-weight-bold
                    artext
                  "
                  style="direction: ltr"
                  >البريد الإلكتروني</v-card-title
                >
                <div class="text-left pl-4 meticula-tiny">
                  Info@shamiderma.com
                </div>
              </div>
            </v-card>
            <br class="hidden-sm-and-down" />
            <br class="hidden-sm-and-down" />
          </v-flex>

          <v-flex xs12 md3 class="hidden-md-and-up">
            <v-card align="center" height="500" outlined class="transparent">
              <v-card-title
                class="
                  cyan--text
                  justify-center
                  meticula
                  hidden-sm-and-down
                  artext
                "
                >ابقى على تواصل</v-card-title
              >
              <v-card-title
                class="
                  cyan--text
                  justify-center
                  meticula-m
                  pt-5
                  hidden-md-and-up
                  artext
                "
                >ابقى على تواصل</v-card-title
              >
              <div>
                <v-card-title
                  class="
                    meticula-small
                    pt-0
                    pb-0
                    justify-center
                    font-weight-bold
                  "
                  >تبوك</v-card-title
                >
                <div class="text-center meticula-tiny artext">
                  الدوار السابع ، عمان ، الأردن
                </div>
                <v-card-title
                  class="
                    pb-0
                    meticula-small
                    justify-center
                    font-weight-bold
                    artext
                  "
                  >ساعات العمل</v-card-title
                >
                <div class="text-center meticula-tiny artext">
                  السبت - الأربعاء
                </div>
                <div class="text-center meticula-tiny">9:00 AM - 5:00 PM</div>
                <div class="text-center meticula-tiny pt-2 artext">الخميس</div>
                <div class="text-center meticula-tiny">9:00 AM - 2:00 PM</div>
                <v-card-title
                  class="pb-0 meticula-small justify-center font-weight-bold"
                  >هاتف</v-card-title
                >
                <div class="text-center meticula-tiny">+962 6 5822626</div>
                <div class="text-center meticula-tiny">+962 79 9451777</div>
                <div class="text-center meticula-tiny">+962 79 7676762</div>

                <v-card-title
                  class="pb-0 meticula-small justify-center font-weight-bold"
                  >البريد الإلكتروني</v-card-title
                >
                <div class="text-center meticula-tiny">Info@shamiderma.com</div>
              </div>
            </v-card>
            <br class="hidden-sm-and-down" />
            <br class="hidden-sm-and-down" />
          </v-flex>

          <v-spacer />

          <v-flex
            style="background-color: #eaf8fa"
            v-if="!submitLocal"
            xs12
            md12
          >
            <br class="hidden-sm-and-down" />
            <br class="hidden-sm-and-down" />
            <div style="padding: 0 10% 0 10%" :class="getFormWidth()">
              <v-card outlined class="transparent">
                <br />
                <h1
                  class="
                    pt-2
                    pb-3
                    cyan--text
                    justify-center
                    text-center
                    meticula-reg
                    pl-1
                    hidden-md-and-up
                    lineh
                    artext
                  "
                >
                  املأ معلوماتك وسنتصل بك
                </h1>

                <h1
                  class="
                    pt-2
                    cyan--text
                    justify-center
                    text-center
                    meticula-reg
                    pl-1
                    hidden-md-and-down
                    lineh1
                    artext
                  "
                >
                  املأ معلوماتك وسنتصل بك
                </h1>
              </v-card>
              <div class="center2 hidden-md-and-down">
                <Form color1="white"></Form>
              </div>
              <div class="hidden-md-and-up">
                <v-card outlined class="transparent" align="center">
                  <Form color1="white"></Form>
                </v-card>
              </div>
              <br />
              <br />
            </div>
          </v-flex>
        </v-row>
      </div>
    </v-card>
  </div>
</template>

<script>
// @ is an alias to /src
import Form from "../components/Form";

export default {
  name: "Contact",
  components: {
    Form,
  },

  data: () => ({
    windowWidth: (window.innerWidth - 100) / 2.5,
    windowWidthPhone: window.innerWidth - 100,
    submitLocal: sessionStorage["submitted"],
  }),

  metaInfo: {
    title: "Shami Derma | Contact Us",
    meta: [
      {
        name: "description",
        content:
          "Shami Derma Jordan Offers A Complimentary Consultation! Contact Us Today!",
      },
    ],
  },

  methods: {
    getWidthMap() {
      if (window.innerWidth > 1000) {
        return this.windowWidth;
      } else {
        return "";
      }
    },
    getFormWidth() {
      if (window.innerWidth > 1000) {
        return "center1";
      } else {
        return "";
      }
    },
    getFormWidth2() {
      if (window.innerWidth > 1000) {
        return "center2";
      } else {
        return "center1";
      }
    },
    getMapDivClass() {
      if (window.innerWidth > 1000) {
        return "center4 transparent hidden-md-and-down";
      } else {
        return "center3 transparent hidden-md-and-top";
      }
    },
    isiten() {
      if (localStorage.getItem("lang") == "EN") {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
.meticula {
  font-family: meticula;
  font-size: calc(12px + 1vw);
}

h1 {
  font-weight: lighter;
}

.meticula-m {
  font-family: meticula;
  font-size: calc(12px + 1rem);
}

.meticula-title {
  font-family: meticula;
  font-size: calc(30px + 1vw);
}

.meticula-small {
  font-family: meticula;
  font-size: 1.2rem;
  color: #696969;
}

.meticula-tiny {
  font-family: meticula;
  font-size: 1rem;
  color: #808080;
}

.meticula-reg {
  font-family: meticula;
  font-size: calc(20px + 1vw);
}

.artext {
  font-family: ar !important;
}

.center1 {
  margin: auto;
  width: 80%;
  padding: 10px;
}

.center2 {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.center3 {
  margin: auto;
  width: 75%;
  padding: 0px;
}

.center4 {
  margin: auto;
  width: 50%;
  padding: 0px;
}

.lineh {
  line-height: 27px;
}

.lineh1 {
  line-height: 40px;
}
</style>
